<template>
  <section>
      <div>
        <!-- <div>{{formatDate}}</div> -->
        <!-- <div  contenteditable="true"><p>Lorem ipsum</p></div>
        <p align="center" color="red"><font color="red"><b>Lorem ipsum</b></font>akory</p> -->
        <!-- <button @click="deleteAllProducts()">supprimer tous les produits</button> -->
        <!-- <button @click="deleteAllOrders()">supprimer tous les orders</button> -->
        <!-- <button @click="putproduct()">Put product</button> -->
        <!-- <h3>{{roundNumber}}</h3> -->
        <!-- <button @click="putservice()">Put service</button> -->
        <!-- <button @click="putAllProducts()">Put service</button> -->
        <!-- <input type="number" v-model="duration1"> -->
        <!-- <b-form-group id="duration" label="Durée (hh:mm)" label-for="example-input-1">
            <b-form-timepicker 
              v-model="times"
              id="example-input-1"
              name="example-input-1"
              minutes-step="5"
              no-close-button
              locale="fr">
              </b-form-timepicker>
        </b-form-group> -->
        <!-- <h3>Résultat en float: {{float}}</h3>
        <button @click="test()">test</button> -->
        <!-- <button @click="putListUsers()">PUT</button> -->

  <p>{{list}}</p>
      </div>
  </section>
</template>

<script>
import axios from 'axios';
// import ttEditor from '../../components/element/tt-editor.vue';
// import styleSidebar from '../../components/style-sidebar.vue';
export default {
  name: "test",
  display: "Clone",
  order: 2,
  components: {
    // styleSidebar,
    // ttEditor
  },
  data() {
    return {
      list1:['jaune','blanc','rouge'],
      list2:[
        {
        color:"jaune",
        id:"1"
      },
        {
        color:"blanc",
        id:"2"
      },
        {
        color:"vert",
        id:"3"
      }
      ],
      data:"<p>Ceci est un test</p>",
      products:[],
      duration1:0.84,
      float:1,
      times:'',
      floatnumber:1.99,
      date:"2022-05-03T12:10:00+02:00",
      users:[]
    };
  },
  computed: {
    list(){
      return this.list2.filter(x => !this.list1.includes(x.color))
    },
    rows() {
      return this.paginateItems.length;
    },
    duration(){
      return this.calculTimes(this.duration1)
    },
    formatDate(){
      // prendre le décalage horaire
        let utc = this.date.charAt(21)
        // utc == heure 
        // let utc = this.date.split('+')
        // console.log(utc[1]);
        let date = new Date(this.date)
        console.log(date.toISOString());
        let originalUTC = date.toISOString().split('T')[1].slice(0,5)
        let getHour = parseInt(originalUTC.split(':')[0])
        let displayedHour = getHour + parseInt(utc)
        console.log({originalUTC});
        console.log({getHour});
        console.log({displayedHour});

        // date = date.toISOString().split('T')[0]+' '+ date.toISOString().split('T')[1].slice(0,5)
        date = date.toISOString().split('T')[0]+' '+ displayedHour.toString()+date.toISOString().split('T')[1].slice(2,5)
        return date
    },
    // roundNumber(){
    //   let roundNumber = Number(this.floatnumber).toFixed(1)
    //   let string= roundNumber.toString().split('.')
    //   if (string[1]==0){
    //     return Number(this.floatnumber).toFixed(0)
    //   }else{
    //     return Number(this.floatnumber).toFixed(1)
    //   }
    // }
  },
  watch:{
    duration1(value){
      this.times = this.calculTimes(value)
      this.float = this.TimeToFloat(this.times)
    }
  },
  created() {
    // console.log('Number',this.roundNumber(1.49)); 
    this.getUsers()
  },
  methods: {
    
    saveStyle(backgroundColor){
      console.log('test',backgroundColor);
    }, 
    comparaison(){
      let schedules = []
      let reservations = []
      for (let schedule of schedules){
        for(let reservation of reservations){
          if (schedule.datestart < reservation.datestart < schedule.dateend || schedule.datestart< reservation.datestart < schedule.dateend){
            return false
          }
        }
      }
    },
    roundNumber(price){
      let roundNumber = Number(price).toFixed(1)
      let string= roundNumber.toString().split('.')
      if (string[1]==0){
        return Number(price).toFixed(0)
      }else{
        return Number(price).toFixed(1)
      }
    },
    // testModify() {
    //   this.categories = [
    //     ["a", "b", "c"],
    //     ["d", "e", "f"],
    //   ];
    // },
    // deleteAllProducts() {
    //   axios.get('products')
    //   .then(resProducts => {
    //      let products=resProducts.data.result
    //     for(let x of products) {
    //       axios.delete(`products/${x.ulid}`, {
    //         headers: {
    //           'X-AUTH-TOKEN': localStorage.getItem('token')
    //         }
    //       })
    //       .then(resDel=> console.log(resDel))
    //       .catch(errDel=> errDel)
    //     }
    //   })
    //   .catch(err => console.log(err))
    // },
    // deleteAllOrders() {
    //   axios.get('orders')
    //   .then(async resOrders => {
    //      let orders=resOrders.data.result
    //     for(let x of orders) {
    //       if(x.ulid != "01FY46XE8PN27S18ZJYS2GEFKZ"){
    //         await axios.delete(`orders/${x.ulid}`, {
    //           headers: {
    //             'X-AUTH-TOKEN': localStorage.getItem('token')
    //           }
    //         })
    //         .then(resDel=> console.log(resDel))
    //         .catch(errDel=> errDel)
    //       }
    //     }
    //   })
    //   .catch(err => console.log(err))
    // },
    putproduct(){
      axios.get('products')
      .then(async resProducts => {
         let products=resProducts.data.result
        for(let x of products) {
          // console.log(Number(this.roundNumber(x.price)));
          await axios.put(`products/${x.ulid}`,{
            price: Number(this.roundNumber(x.price))
          }, {
            headers: {
              'X-AUTH-TOKEN': localStorage.getItem('token')
            }
          })
          .then(resPut=> console.log(resPut))
          .catch(errPut=> errPut)
        }
      })
      .catch(err => console.log(err))
    },
    // putservice(){
    //   axios.get('services')
    //   .then(resServices => {
    //      let services=resServices.data.result
    //     for(let x of services) {
    //       axios.put(`services/${x.ulid}`,{
    //         isactive:true,
    //       }, {
    //         headers: {
    //           'X-AUTH-TOKEN': localStorage.getItem('token')
    //         }
    //       })
    //       .then(resPut=> console.log(resPut))
    //       .catch(errPut=> errPut)
    //     }
    //   })
    //   .catch(err => console.log(err))
    // },
    getUsers(){
      this.user_display = null
      axios.get('api/users',
      {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('main-token') }
      })
      .then(resGetUser=> {
        this.users=resGetUser.data.result
        this.users= this.users.filter(user => !user.isactive)
        })
      .catch(errGetUser=> console.log(errGetUser))
    },
    async putListUsers(){
      this.user_display = null
      axios.get('api/users',
      {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('main-token') }
      })
      .then(async resGetUser=> {
        let users=resGetUser.data.result
        users = this.users.filter(user => !user.isactive)
          for(let user of users){
           await axios.put(`api/users/${user.ulid}`,
            {
              isactive:true
            },
            {
              headers: { Authorization: 'Bearer ' + localStorage.getItem('main-token') }
            }
            )
            .then(resPutUsers=> console.log(resPutUsers))
            .catch(errPutUsers=> console.log(errPutUsers))
          }
        })
      .catch(errGetUser=> console.log(errGetUser))

    }
  },
};
</script>
<style scoped>
.composant {
    display: none;
}
</style>